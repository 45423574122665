import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API } from "../../../util/constants";
import { AxiosError, AxiosResponse } from "axios";
import { UpdateYearlyReflectionResponse } from "../../../api/api-client";

const initialState: YearlyReflectionState = {
  yearlyReflection: null,
  yearlyReflectionLoadingStatus: "idle",
  yearlyFeedback: null,
  yearlyFeedbackStatus: "idle",
};

export const getYearlyReflection = createAsyncThunk(
  "yearlyReflection/getYearlyReflection",
  async ({ apiToken }: { apiToken: string }, { dispatch, rejectWithValue }) => {
    try {
      const response = (await API.yearlyReflection.getYearlyReflection({
        api_token: apiToken,
      })) as AxiosResponse<YearlyReflection>;

      dispatch(
        YearlyReflectionSlice.actions.appendFeedbackChunk(
          response.data.gpt_suggestion
        )
      );
      delete response.data.status;
      return response.data as YearlyReflection;
    } catch (err: any) {
      return rejectWithValue(err.response?.data || "Unexpected error");
    }
  }
);

export const updateYearlyReflectionMonth = createAsyncThunk(
  "yearlyReflection/updateYearlyReflectionMonth",
  async (
    {
      apiToken,
      month,
      value,
    }: { apiToken: string; month: keyof YearlyReflection; value: string },
    { rejectWithValue }
  ) => {
    try {
      console.log(value);
      (await API.yearlyReflection.updateYearlyReflection(
        {
          api_token: apiToken,
          target: month,
        },
        { new_text: value }
      )) as AxiosResponse<UpdateYearlyReflectionResponse>;
      return { month, value } as {
        month: keyof YearlyReflection;
        value: string;
      };
    } catch (err: any) {
      return rejectWithValue(err.response?.data || "Unexpected error");
    }
  }
);

export const generateYearlyFeedback = createAsyncThunk(
  "yearlyReflection/generateYearlyFeedback",
  async ({ apiToken }: { apiToken: string }, { dispatch, rejectWithValue }) => {
    dispatch(YearlyReflectionSlice.actions.resetFeedback());
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/generate_yearly_feedback?api_token=${apiToken}`,
        {
          method: "POST",
        }
      );

      if (response.status !== 200) {
        return rejectWithValue(response);
      }

      if (response.ok && response.body) {
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let result = "";

        while (true) {
          const { done, value } = await reader.read();

          if (done) return result;

          const chunk = decoder.decode(value, { stream: true });

          if (chunk.includes("END_OF_LINE")) {
            // Удаляем END_OF_LINE, добавляем результат и завершаем
            const cleanedChunk = chunk.replace("END_OF_LINE", "").trim();
            result += cleanedChunk;

            if (cleanedChunk) {
              dispatch(
                YearlyReflectionSlice.actions.appendFeedbackChunk(cleanedChunk)
              );
            }

            return result; // Выходим из цикла, так как достигли END_OF_LINE
          }

          result += chunk;

          if (chunk.trim()) {
            dispatch(YearlyReflectionSlice.actions.appendFeedbackChunk(chunk));
          }
        }
      } else {
        // Обработка других ошибок
        const errorMessage = `Unexpected response: ${response.statusText}`;
        console.error(errorMessage);
        return rejectWithValue(errorMessage);
      }
    } catch (err: any) {
      console.error(err);
      return rejectWithValue(err || "Unexpected error occurred");
    }
  }
);

const YearlyReflectionSlice = createSlice({
  name: "yearlyReflection",
  initialState,
  reducers: {
    setYearlyFeedbackStatus: (state, { payload }) => {
      state.yearlyFeedbackStatus = payload;
    },
    appendFeedbackChunk: (state, { payload }) => {
      if (!state.yearlyFeedback) {
        state.yearlyFeedback = payload;
      } else {
        state.yearlyFeedback += payload;
      }
    },
    resetFeedback: (state) => {
      state.yearlyFeedback = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getYearlyReflection.pending, (state) => {
        state.yearlyReflectionLoadingStatus = "loading";
      })
      .addCase(getYearlyReflection.fulfilled, (state, action) => {
        state.yearlyReflectionLoadingStatus = "idle";
        state.yearlyReflection = action.payload;
      })
      .addCase(getYearlyReflection.rejected, (state) => {
        state.yearlyReflectionLoadingStatus = "error";
      })
      .addCase(updateYearlyReflectionMonth.pending, (state) => {
        state.yearlyReflectionLoadingStatus = "loading";
      })
      .addCase(updateYearlyReflectionMonth.fulfilled, (state, action) => {
        state.yearlyReflectionLoadingStatus = "idle";
        if (state.yearlyReflection) {
          const { month, value } = action.payload;
          state.yearlyReflection[month] = value;
        }
      })
      .addCase(updateYearlyReflectionMonth.rejected, (state) => {
        state.yearlyReflectionLoadingStatus = "error";
      })
      .addCase(generateYearlyFeedback.pending, (state) => {
        state.yearlyReflectionLoadingStatus = "loading";
        state.yearlyFeedbackStatus = "loading";
      })
      .addCase(generateYearlyFeedback.fulfilled, (state, action) => {
        state.yearlyReflectionLoadingStatus = "idle";
        state.yearlyFeedbackStatus = "idle";
      })
      .addCase(generateYearlyFeedback.rejected, (state, action) => {
        state.yearlyReflectionLoadingStatus = "error";
        console.log("rejected");
        if ((action.payload as AxiosError).status === 403) {
          state.yearlyFeedbackStatus = "forbidden";
        }
      });
  },
});

const { actions, reducer } = YearlyReflectionSlice;

export const { setYearlyFeedbackStatus, appendFeedbackChunk, resetFeedback } =
  actions;

export default reducer;
