import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Translator from "../../resources/localization/translator";
import { API, TELEGRAM } from "../../util/constants";
import { sendMetrics } from "../../util/metrics";
import "./PageNavigation.scss";
import { setQueryToggle } from "../../store/slices/calendarData";

const PageNavigation = () => {
  const dispatch = useDispatch();
  const { apiToken, language, viewedByAdmin, gender } = useSelector(
    (state) => state.userParams
  );
  const { datesWithCheckup } = useSelector((state) => state.datesWithCheckup);
  const { currentPage } = useSelector((state) => state.pageNavigation);

  const { isOwnerDiary } = useSelector((state) => state.sharingData);

  const openSettings = async (data) => {
    try {
      const response =
        await API.openDiarySettings.openDiarySettingsPostOpenDiarySettingsPost({
          api_token: apiToken,
        });

      return response;
    } catch (err) {
      let error = err;
      if (!error.response) throw err;

      return error;
    }
  };
  const sendData = () => {
    TELEGRAM.HapticFeedback.impactOccurred("heavy");
    sendMetrics("reachGoal", "click-settings-btn");
    if (!viewedByAdmin) {
      openSettings();
    }
    setTimeout(() => {
      TELEGRAM.close();
    }, 0);
  };
  const clickHandler = () => {
    if (clickHandler.cantClick) return;
    clickHandler.cantClick = true;

    sendData();

    setTimeout(() => {
      clickHandler.cantClick = false;
    }, 500);
  };
  const setActivePage = (page) => {
    TELEGRAM.HapticFeedback.impactOccurred("heavy");

    switch (page) {
      case "calendar":
        if (Object.keys(datesWithCheckup).length !== 0) {
          dispatch(setQueryToggle(false));
        }
        sendMetrics("reachGoal", "click-nav-calendar-btn");
        break;
      case "statistics":
        sendMetrics("reachGoal", "click-nav-statistics-btn");
        break;
      case "yearlyReflections":
        sendMetrics("reachGoal", "click-nav-reflections-btn");
        break;
      default:
        break;
    }
  };
  console.log(currentPage);
  return (
    <>
      {isOwnerDiary && (
        <div className="navigation">
          <Link
            to={`/calendar?token=${apiToken}&language=${language}&gender=${gender}&viewed_by_admin=${viewedByAdmin}`}
          >
            <button
              className={`navigation__item${
                currentPage === "calendar" ? "_active" : ""
              }`}
              onClick={() => setActivePage("calendar")}
            >
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" rx="5" />
                <path
                  d="M10.1053 24.4211C9.6421 24.4211 9.24547 24.2563 8.91537 23.9267C8.58582 23.5966 8.42105 23.2 8.42105 22.7368V10.9474C8.42105 10.4842 8.58582 10.0879 8.91537 9.75832C9.24547 9.42821 9.6421 9.26316 10.1053 9.26316H10.9474V7.57895H12.6316V9.26316H19.3684V7.57895H21.0526V9.26316H21.8947C22.3579 9.26316 22.7545 9.42821 23.0846 9.75832C23.4142 10.0879 23.5789 10.4842 23.5789 10.9474V22.7368C23.5789 23.2 23.4142 23.5966 23.0846 23.9267C22.7545 24.2563 22.3579 24.4211 21.8947 24.4211H10.1053ZM10.1053 22.7368H21.8947V14.3158H10.1053V22.7368Z"
                  fill="#F5F5F5"
                />
              </svg>
              {Translator.translate("CALENDAR_BUTTON", language)}
            </button>
          </Link>
          <Link
            to={`/statistics?token=${apiToken}&language=${language}&gender=${gender}&viewed_by_admin=${viewedByAdmin}`}
          >
            <button
              className={`navigation__item${
                currentPage === "statistics" ? "_active" : ""
              }`}
              onClick={() => setActivePage("statistics")}
            >
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" rx="5" fill="#F8F8F8" />
                <rect
                  className="plot"
                  x="6"
                  y="6"
                  width="6"
                  height="19"
                  fill="#7868E6"
                />
                <rect
                  className="plot"
                  x="13"
                  y="14"
                  width="6"
                  height="11"
                  fill="#7868E6"
                />
                <rect
                  className="plot"
                  x="20"
                  y="9"
                  width="6"
                  height="16"
                  fill="#7868E6"
                />
              </svg>
              {Translator.translate("STATISTICS_BUTTON", language)}
            </button>
          </Link>
          {/* <Link
            to={`/psychologists?token=${apiToken}&language=${language}&viewed_by_admin=${viewedByAdmin}`}
          >
            <button className="navigation__item">
              // onClick={clickHandler}
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" rx="5" fill="#7868E6" />
                <path
                  d="M14.75 23C14.75 23 13.5 23 13.5 21.75C13.5 20.5 14.75 16.75 19.75 16.75C24.75 16.75 26 20.5 26 21.75C26 23 24.75 23 24.75 23H14.75ZM19.75 15.5C20.7446 15.5 21.6984 15.1049 22.4017 14.4017C23.1049 13.6984 23.5 12.7446 23.5 11.75C23.5 10.7554 23.1049 9.80161 22.4017 9.09835C21.6984 8.39509 20.7446 8 19.75 8C18.7554 8 17.8016 8.39509 17.0983 9.09835C16.3951 9.80161 16 10.7554 16 11.75C16 12.7446 16.3951 13.6984 17.0983 14.4017C17.8016 15.1049 18.7554 15.5 19.75 15.5ZM12.52 23C12.3348 22.6097 12.2424 22.1819 12.25 21.75C12.25 20.0562 13.1 18.3125 14.67 17.1C13.8865 16.858 13.07 16.74 12.25 16.75C7.25 16.75 6 20.5 6 21.75C6 23 7.25 23 7.25 23H12.52ZM11.625 15.5C12.4538 15.5 13.2487 15.1708 13.8347 14.5847C14.4208 13.9987 14.75 13.2038 14.75 12.375C14.75 11.5462 14.4208 10.7513 13.8347 10.1653C13.2487 9.57924 12.4538 9.25 11.625 9.25C10.7962 9.25 10.0013 9.57924 9.41529 10.1653C8.82924 10.7513 8.5 11.5462 8.5 12.375C8.5 13.2038 8.82924 13.9987 9.41529 14.5847C10.0013 15.1708 10.7962 15.5 11.625 15.5Z"
                  fill="#F8F8F8"
                />
              </svg>
              {Translator.translate("PSYCHOLOGISTS_BUTTON", language)}
            </button>
          </Link> */}
          <Link
            to={`/yearlyReflections?token=${apiToken}&language=${language}&gender=${gender}&viewed_by_admin=${viewedByAdmin}`}
          >
            <button
              className={`navigation__item${
                currentPage === "yearlyReflections" ? "_active" : ""
              }`}
              onClick={() => setActivePage("yearlyReflections")}
            >
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" rx="5" fill="#7868E6" />
                <path
                  d="M18.9998 23V24C18.9998 24.5305 18.7891 25.0392 18.414 25.4142C18.0389 25.7893 17.5302 26 16.9998 26H14.9998C14.4694 26 13.9607 25.7893 13.5856 25.4142C13.2105 25.0392 12.9998 24.5305 12.9998 24V23H18.9998ZM8.99979 22C8.10979 22 7.66279 20.923 8.29279 20.293L11.1478 17.436L9.68379 16.949C9.53177 16.8985 9.39411 16.8121 9.28243 16.6972C9.17075 16.5823 9.0883 16.4423 9.04204 16.2889C8.99578 16.1355 8.98705 15.9732 9.01659 15.8158C9.04613 15.6583 9.11308 15.5102 9.21179 15.384L9.29179 15.293L12.3108 12.273L11.5528 11.894C11.4206 11.8279 11.3047 11.7333 11.2135 11.617C11.1222 11.5008 11.0579 11.3657 11.0251 11.2216C10.9923 11.0775 10.9918 10.9279 11.0238 10.7836C11.0557 10.6393 11.1192 10.5038 11.2098 10.387L11.2928 10.293L15.2928 6.29303C15.4803 6.10556 15.7346 6.00024 15.9998 6.00024C16.265 6.00024 16.5193 6.10556 16.7068 6.29303L20.7068 10.293C20.8187 10.4051 20.9024 10.5421 20.951 10.6929C20.9996 10.8436 21.0118 11.0037 20.9864 11.16C20.961 11.3164 20.8988 11.4644 20.805 11.592C20.7112 11.7197 20.5884 11.8232 20.4468 11.894L19.6878 12.273L22.7078 15.293C22.8209 15.4063 22.9052 15.5452 22.9535 15.6978C23.0018 15.8505 23.0128 16.0125 22.9854 16.1703C22.9581 16.3281 22.8933 16.477 22.7964 16.6045C22.6995 16.732 22.5735 16.8344 22.4288 16.903L22.3158 16.949L20.8508 17.436L23.7068 20.293C24.3098 20.895 23.9268 21.907 23.1138 21.994L22.9998 22H8.99979Z"
                  fill="#F8F8F8"
                />
              </svg>
              {Translator.translate("Results of the year", language)}
            </button>
          </Link>
          <button className="navigation__item" onClick={clickHandler}>
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="32" height="32" rx="5" fill="#F8F8F8" />
              <path
                d="M14.1949 24.4211L13.8667 21.7263C13.6889 21.6561 13.5212 21.5719 13.3637 21.4737C13.2062 21.3754 13.0524 21.2702 12.9026 21.1579L10.4615 22.2105L8.20513 18.2105L10.3179 16.5684C10.3043 16.4702 10.2974 16.3753 10.2974 16.2838V15.7162C10.2974 15.6247 10.3043 15.5298 10.3179 15.4316L8.20513 13.7895L10.4615 9.78948L12.9026 10.8421C13.053 10.7298 13.2103 10.6246 13.3744 10.5263C13.5385 10.4281 13.7026 10.3439 13.8667 10.2737L14.1949 7.57895H18.7077L19.0359 10.2737C19.2137 10.3439 19.3813 10.4281 19.5389 10.5263C19.6964 10.6246 19.8501 10.7298 20 10.8421L22.441 9.78948L24.6974 13.7895L22.5846 15.4316C22.5983 15.5298 22.6051 15.6247 22.6051 15.7162V16.2838C22.6051 16.3753 22.5915 16.4702 22.5641 16.5684L24.6769 18.2105L22.4205 22.2105L20 21.1579C19.8496 21.2702 19.6923 21.3754 19.5282 21.4737C19.3641 21.5719 19.2 21.6561 19.0359 21.7263L18.7077 24.4211H14.1949ZM16.4923 18.9474C17.2855 18.9474 17.9624 18.6597 18.5231 18.0842C19.0838 17.5088 19.3641 16.814 19.3641 16C19.3641 15.186 19.0838 14.4912 18.5231 13.9158C17.9624 13.3404 17.2855 13.0526 16.4923 13.0526C15.6855 13.0526 15.005 13.3404 14.4509 13.9158C13.8968 14.4912 13.62 15.186 13.6205 16C13.6205 16.814 13.8973 17.5088 14.4509 18.0842C15.0044 18.6597 15.6849 18.9474 16.4923 18.9474Z"
                fill="#DAD9FF"
              />
            </svg>
            {Translator.translate("SETTINGS_BUTTON", language)}
          </button>
        </div>
      )}
    </>
  );
};
export default PageNavigation;
